<template>
  <div>
    <div class="card">
      <div class="card-header">
        <ListViewToolbar
          :addEnable="addEnable"
          :deleteEnable="selectedRepresent"
          @add="onAdd"
          @remove="onRemove"
        >
          <template #moreButtons>
            <a-button
              title="Обновить названия полей во всех представлениях"
              :loading="refreshListviewPresetsLoading"
              @click="refreshListviewPresets"
            >
              <template #icon><ReloadOutlined /></template>
            </a-button>
          </template>
        </ListViewToolbar>
      </div>

      <div class="row">
        <div class="col-3">
          <a-tree
            :tree-data="treeData"
            :selected-keys="selectedKeys"
            @select="onSelect"
          >

          </a-tree>
        </div>
        <div class="col-9 p-4">
          <div v-if="selectedRepresent">
            <SpSimpleFormGenerator
              :items="modalEditFormFields"
              :represent="represent"
              :objectData="editItem"
              @initialized="onEditFormInitialized"
            />
            <a-button
              type="primary"
              @click="saveEditItem"
            >Сохранить</a-button>
          </div>
          <grid
            v-else
            :toolbarVisible="false"
            :patchProps="patchProps"
            :filters="filters"  
            :representService="gridRepresentService"
          />
        </div>
      </div>
    </div>

    <a-modal
      title=""
      :visible="modalVisible"
      :confirm-loading="modalSaving"
      @ok="save"
      @cancel="handleCancel"
    >
      <SpSimpleFormGenerator        
        :items="modalFormFields"
        :represent="represent"
        :objectData="modalItem"
        @initialized="onFormInitialized"
      />
    </a-modal>

  </div>
</template>

<script>
import CrudService from "@/services/crud-service"
import ListViewToolbar from "@/views/base/ListViewToolbar"
import grid from "@/components/grid.vue"
import SpSimpleFormGenerator from "@/components/form-generation/sp-simple-form-generator.vue"
import {computed, onMounted, ref} from "vue"
import RepresentService from "@/services/represent-service"
import {error, success} from "@/helpers/notifications"
import { ReloadOutlined } from "@ant-design/icons-vue";
import api from "@/api"

export default {
  name: "represent",
  components:{
    grid,
    ListViewToolbar,
    SpSimpleFormGenerator,
    ReloadOutlined
  },
  setup(){
    const filters = ref([{field: "Id", equal: "0"}])
    const selectedRows = ref([])
    const selectedKeys = ref([])
    const treeData = ref([])
    const modalItem = ref({})
    const editItem = ref({})
    const modalForm = ref({})
    const editForm = ref({})
    const patchProps = ref({})
    const modalVisible = ref(false)
    const modalSaving = ref(false)
    const representCrudService = new CrudService("Represent")
    const entityMetadataCrudService = new CrudService("EntityMetadata")
    const representRepresentService = new RepresentService("Represent")
    const formFields = ref([])
    const gridRepresentService = new RepresentService("EntityField")
    
    const represent = ref({})

    const addEnable = computed(() =>{
      return selectedRows.value.length > 0 
    })

    const selectedRepresent = computed(() =>{
      return isSelectedRepresent()
    })
    
    const isSelectedRepresent = function(){
      return selectedRows.value.filter(function (node) {
        return node.props.type === "represent"
      }).length !== 0
    }
    
    const onFormInitialized = function(form) {
      modalForm.value = form 
    }

    const onEditFormInitialized = function(form) {
      editForm.value = form
    }

    const modalFormFields = computed(() => {
      let fields = [];
      formFields.value.forEach(item => {
        let field = {
          name: item.name,
          label: item.label,
          type: item.type,
        }
        if (modalItem.value)
          field.value = modalItem.value[item.name];
        fields.push(field)
      });

      return fields
    })

    const modalEditFormFields = computed(() => {
      let fields = [];
      formFields.value.forEach(item => {
        let field = {
          name: item.name,
          label: item.label,
          type: item.type,
        }
        if (editItem.value)
          field.value = editItem.value[item.name];
        fields.push(field)
      });

      return fields
    })

    const saveEditItem = async function(){
      modalForm.value = editForm.value
      modalItem.value = editItem.value
      await save()
    }

    const save = async function(){
      modalSaving.value = true

      let model = { ...modalForm.value.changedState }
      model.entityId = getEntityMetadataId()

      try {
        if (!modalItem.value.id){
          await representCrudService.create(model)
        }
        else {
          model.id = modalItem.value.id
          await representCrudService.update(model)
        }
        success("Сохранено")

        hideModal()
        await loadData()
      } catch(e) {
        error("Ошибка при сохранении")
      }
    } 

    const hideModal = function() {
      modalVisible.value = false 
      modalSaving.value = false 
      modalItem.value = null 
    } 
    
    const getEntityMetadataId = function(){
      let entityMetadataId
      if(isSelectedRepresent())
        entityMetadataId = selectedRows.value[0].props.parentId
      else
        entityMetadataId = selectedRows.value[0].props.id
      
      return entityMetadataId;
    }

    const onAdd = function(){
      if(!addEnable.value) return 
      modalVisible.value = true 
    }
    const onRemove = async function(){
      const promises = selectedRows.value.map((item) => {
        return representCrudService.remove(item.props.represent.id) 
      }) 

      await Promise.all(promises) 
      await loadData() 
    }
    const onSelect = function(srk, sr) {
      selectedKeys.value = srk 
      selectedRows.value = sr.selectedNodes
      let entityMetadataId = getEntityMetadataId()
      filters.value = [{field: "EntityId", equal: entityMetadataId}]
      patchProps.value = {entityId: entityMetadataId}

      editItem.value = selectedRows.value[0].props.represent
    } 
    const loadData = async function(){
      let entities = (await entityMetadataCrudService.gridData()).data;
      treeData.value = entities
        .filter((i) => {
          return i.name === "SampleProgram"
        })        
        .map(function(i) {
          return {
            title: i.name,
            key: "entity-" + i.id,
            id: i.id,
            type: "entity",
            children: i.represents?.map((r) => {
              return {
                represent: r,
                title: r.systemName,
                key: "represent-" + r.id,
                type: "represent",
                parentId: i.id
              }
            })
          }
      })
    }
    
    const handleCancel = function(){
      hideModal()
    }

    const refreshListviewPresetsLoading = ref(false);
    const refreshListviewPresets = async () => {
      refreshListviewPresetsLoading.value = true;
      try {
        await api.entityConfig.representRefreshListviewPresets();
        success("Обновление представлений успешно завершено");
      }
      catch(e) {
        console.error(e);
        error("Обновление представлений завершено с ошибкой");
      }
      finally {
        refreshListviewPresetsLoading.value = false;
      }
    }


    onMounted(async () => {
      await loadData()
      represent.value = await representRepresentService.get()
      formFields.value = represent.value.formFields
    })

    return {
      selectedRows,
      selectedKeys,
      treeData,
      gridRepresentService,
      modalVisible,
      modalSaving,
      modalFormFields,
      modalEditFormFields,
      selectedRepresent,
      addEnable,
      filters,
      patchProps,
      represent,
      modalItem,
      editItem,

      onAdd,
      onSelect,
      onRemove,
      onFormInitialized,
      onEditFormInitialized,
      save,
      saveEditItem,
      handleCancel,

      refreshListviewPresets,
      refreshListviewPresetsLoading
    }
  }
} 
</script>