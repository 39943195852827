<template>
  <div>
    <a-page-header title="Настройка меню" />

    <a-radio-group v-model:value="selectedMenuType">
      <a-radio 
        v-for="(key, value) in menuTypes" 
        :value="value" 
        :key="key"
        class="d-block"
      >
        {{key}}
      </a-radio>
    </a-radio-group>
    <div class="card">
      <div class="card-header">
        <a-button class="mr-2" @click="openEditDialog(editDialogActions.createRoot)">
          <template #icon><i class="fa fa-plus" /></template>
        </a-button>
        <a-button class="mr-2" :disabled="!selectedNode" @click="openEditDialog(editDialogActions.create)">
          <template #icon><i class="fa fa-plus-circle" /></template>
        </a-button>
        <a-button class="mr-2" :disabled="!selectedNode" @click="openEditDialog(editDialogActions.edit)">
          <template #icon><i class="fa fa-pencil" /></template>
        </a-button>
        <a-button class="mr-2" :disabled="!selectedNode" @click="deleteNode()">
          <template #icon><i class="fa fa-trash" /></template>
        </a-button>
        <a-button class="mr-2" :disabled="!tempPatchData.length" @click="SavePosition()">
          <template #icon><i class="fa fa-save" /></template>
        </a-button>
      </div>
      <div class="card-body">
        <div v-show="!treeData || !treeData.length">Нет данных</div>
        <a-spin :spinning="savingPosition">
          <Tree :value="treeData" ref="tree" @drop="onDrop">
            <template #default="{ node, path, tree }">
              <span class="tree-node-item">
                <i 
                  v-if="node.children && !!node.children.length" 
                  @click="tree.toggleFold(node, path)" 
                  class="mr-2 align-self-md-center fa"
                  :class="node.$folded ? 'fa-plus-square-o' : 'fa-minus-square-o'"
                  style="width: 13px;"
                />
                <span v-else class="mr-4"></span>
                <div 
                  class="tree-node-content px-2 py-1"
                  :class="{'bg-gray-2': selectedNode == node }"
                  @click="selectNode(node, path)"
                  @dblclick="openEditDialog(editDialogActions.edit)"
                >
                  {{ node.title }}
                  <i v-if="node.icon" :class="node.icon" />
                </div>
              </span>
            </template>
          </Tree>
        </a-spin>
      </div>
    </div>
    <MenuManagerEditDialog 
      :visible="editDialogVisible"
      :item="editableItem"
      :save-action="editDialogAction"
      destroyOnClose
      @close="editDialogVisible = false"
      @save="onDialogSave"
    />
  </div>
</template>

<script>
import axios from "axios";
import { Tree, Draggable, Fold } from 'he-tree-vue';
import sortBy from "lodash/sortBy";
import MenuManagerEditDialog from "./ManagerEditDialog.vue";
import { showDeleteConfirm } from "@/helpers/modals.js";
import { error } from "@/helpers/notifications.js";

const baseUrl = "MainMenuManage";
const baseItemUrl = baseUrl + "/item";
const dialogActions = {
  createRoot: "create_root",
  create: "create",
  edit: "edit",
}

export default {
  name: "MainMenuManager",
  components: { 
    Tree: Tree.mixPlugins([Draggable, Fold]),
    MenuManagerEditDialog
  },
  data() {
    return {
      menuTypes: {},
      selectedMenuType: null,
      treeData: [],
      selectedNode: null,
      selectedNodePath: null,
      editDialogVisible: false,
      editableItem: null,
      editDialogActionType: dialogActions.create,
      editDialogActions: dialogActions,
      savingPosition: false,
      tempPatchData: [],
    };
  },
  methods: {
    selectNode(node, path) {
      this.selectedNode = node;
      this.selectedNodePath = path;
    },
    unselectNode() {
      this.selectNode(null, null);
    },
    openEditDialog(actionType) {
      let isEdit = actionType === dialogActions.edit;
      this.editableItem = isEdit ? this.selectedNode : null;

      this.editDialogActionType = actionType;
      this.editDialogVisible = true;
    },
    deleteNode() {
      if (this.selectedNode.children?.length) {
        error("Невозможно удалить пункт меню, включающий в себя дочерние пункты");
        return;
      }
      showDeleteConfirm(async () => {
        await axios.delete(`${baseItemUrl}?id=${this.selectedNode.id}`);
        this.$refs.tree.removeNodeByPath(this.selectedNodePath);
        this.unselectNode();
      });
    },
    async editNode(form) {
      return (await axios.patch(`${baseItemUrl}?id=${this.selectedNode.id}`, form)).data;
    },
    onNodeEdited(updatedNode) {
      this.selectedNode.title = updatedNode.title;
      this.selectedNode.url = updatedNode.url;
      this.selectedNode.icon = updatedNode.icon;
    },
    async createNode(form) {
      form.ownerType = this.selectedMenuType;
      if (this.editDialogActionType === dialogActions.create)
        form.parentId = this.selectedNode.id;
        
      return (await axios.post(`${baseItemUrl}`, form)).data;
    },
    onNodeCreated(newNode) {
      if (!newNode.parentId) {
        this.treeData.push(newNode);
        this.treeData = this.reorderItems(this.treeData);
      }
      else { 
        if (this.selectedNode.children == null)
          this.selectedNode.children = [];
        this.selectedNode.children.push(newNode);
        this.selectedNode.children = this.reorderItems(this.selectedNode.children);
      }
    },
    onDialogSave(resultNode) {
      if (this.editDialogActionType === dialogActions.edit)
        return this.onNodeEdited(resultNode);
      return this.onNodeCreated(resultNode);
    },
    async onDrop(store) {      
      if (!store.pathChanged)
        return;
      
      let newParent = this.$refs.tree.getNodeParentByPath(store.targetPath);
      let currentNode = store.dragNode;
      currentNode.parentId = newParent?.id;
      
      let itemsToOrder = (newParent ? newParent.children : this.treeData);
      itemsToOrder.forEach((x, index) => x.sortOrder = index);
      
      this.tempPatchData = this.tempPatchData.concat({
        currentNodeId: currentNode.id, 
        newParentId: newParent?.id,
        data: itemsToOrder.map(x => ({id: x.id, sortOrder: x.sortOrder}))
      });
    },
    async SavePosition(){
      this.savingPosition = true;

      this.tempPatchData.forEach(async (item) => {
        try {
          await axios.patch(`${baseItemUrl}/position?id=${item.currentNodeId}&newParentId=${item.newParentId}`, item.data);
        }
        catch (e) {
          error("Ошибка при сохранении данных");
          console.error(e);
        }
      });
      this.savingPosition = false;
    },
    reorderTreeRecursive(tree) {
      return parseMenuItems(tree);

      function parseMenuItems(items) {
        return sortBy(items.map(item => {
          item.children = item.children?.length ? parseMenuItems(item.children) : null;
          return item;
        }), x => x.sortOrder);
      }
    },
    reorderItems(items) {
      return sortBy(items, x => x.sortOrder);
    }
  },
  computed: {
    editDialogAction() {
      if (this.editDialogActionType === dialogActions.edit)
        return this.editNode;

      return this.createNode;
    }
  },
  watch: {
    selectedMenuType: {
      immediate: true,
      async handler(value) {
        let data = (await axios.get(`${baseUrl}?ownerType=${value}`)).data;
        this.treeData = this.reorderTreeRecursive(data);
      }
    }
  },

  async created() {
    this.menuTypes = (await axios.get(baseUrl + "/types")).data;
    this.selectedMenuType = Object.keys(this.menuTypes)[0];
  }
}
</script>

<style scoped lang="less">
  .tree-node-item {
    cursor: pointer;
  }
</style>