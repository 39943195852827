<template>
  <a-modal 
    v-model:visible="innerVisible"
    title="Пункт меню"
    :confirm-loading="loading"
    @ok="onOk"
    @cancel="onCancel"
  >
    <a-form
      :model="form"
      :rules="rules"
      ref="form"
      layout="vertical"
      class="mb-4"
      @finish="sendData"
      @finishFailed="handleFinishFailed"
    >
      <a-form-item name="title" label="Название">
        <a-input v-model:value="form.title" />
      </a-form-item>

      <a-form-item name="icon" label="Иконка">
        <a-button 
          v-if="!!form.icon" 
          size="large" 
          class="mr-2"
          @click="iconDialogVisible = true"
        >
          <template #icon><i :class="form.icon" /></template>
        </a-button>
        <a-button 
          v-else
          @click="iconDialogVisible = true"
        >
          <span v-if="!form.icon">Выбрать</span>
        </a-button>
        <a-button 
          v-show="!!form.icon" 
          @click="form.icon = null"
        >
          Сбросить
        </a-button>
        <iconDialog
          :visible="iconDialogVisible"
          :value="form.icon"
          @close="iconDialogVisible = false"
          @save="(v) => form.icon = v"
        />
      </a-form-item>

      <a-form-item name="url" label="Ссылка">
        <a-input v-model:value="form.url" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import iconDialog from '@/components/sp-icon-select-dialog.vue';
import { required } from "@/helpers/validation.js";

export default {
  name: "MenuManagerEditDialog",
  components: { iconDialog },
  props: {
    visible: { type: Boolean, required: true },
    item: { type: Object, default: () => ({}) },
    saveAction: { type: Function, required: true }
  },
  emits: ["close", "save"],
  data() {
    return {
      innerVisible: false,
      iconDialogVisible: false,
      form: {
        title: null,
        icon: null,
        url: null,
      },
      loading: false,
      rules: {
        title: [required("Название")],
        url: [required("Ссылка")],
      }
    }
  },
  watch: {
    visible: {
      handler(value) {
        if (value != this.innerVisible)
          this.innerVisible = value;
        if (value)
          this.bindItemData();
      },
      immediate: true
    }
  },
  methods: {
    bindItemData() {
      this.form.title = this.item?.title;
      this.form.url = this.item?.url;
      this.form.icon = this.item?.icon;
    },
    async onOk() {
      await this.$refs.form.validate();
      await this.sendData();
    },
    async sendData() {
      this.loading = true;
      let result = false;
      try {
        result = await this.saveAction({...this.form});
      }
      catch (e) {
        console.log(e);
      }
      this.loading = false;
      this.$emit("close");
      this.$emit("save", result);
    },
    onCancel() {
      this.$emit("close");
    },
    handleFinishFailed(e) {
      console.log(e);
    }
  }
}
</script>