<template>
  <a-modal 
    centered
    v-model:visible="innerVisible"
    title="Выбор иконки"
    @ok="onOk"
    @cancel="onCancel"
  >
    <a-tabs v-model:activeKey="activeTab">
      <a-tab-pane 
        v-for="(tab, index) in tabs" 
        :key="index" 
        :tab="tab.title" 
        class="tab-content"
      >
        <ul class="vb__utils__iconPresent list-unstyled">
          <li 
            v-for="icon in tab.icons" 
            :key="icon" 
            class="btn"
            :class="selected === tab.class + icon ? 'btn-primary' : null"
            @click="onSelect(tab.class + icon)"
          >
            <i :class="[tab.class, icon]" />
          </li>
        </ul>
      </a-tab-pane>
    </a-tabs>
  </a-modal>
</template>

<script>
import feIcons from '@/@vb/widgets/Hidden/IconsFeather/data.json';
import faIcons from '@/@vb/widgets/Hidden/IconsFontawesome/data.json';
import icoIcons from '@/@vb/widgets/Hidden/IconsIcomoonFree/data.json';
import liIcons from '@/@vb/widgets/Hidden/IconsLineariconsFree/data.json';
import flatten from 'lodash/flatten';
import uniq from 'lodash/uniq';
export default {
  name: "sp-icon-select-dialog",
  props: {
    visible: { type: Boolean, required: true },
    value: { type: String }
  },
  emits: ["close", "save"],
  data() {
    return {
      innerVisible: false,
      selected: null,
      activeTab: null,
      tabs: [
        { title: 'Feather', icons: feIcons, class: 'fe ', prefix: 'fe-' },
        { title: 'FontAwesome', icons: uniq(flatten(faIcons.map(x => x.icons))), class: 'fa ', prefix: 'fa-' },
        { title: 'IcoMoon', icons: icoIcons, class: '', prefix: 'icmn-' },
        { title: 'LinearIcons', icons: liIcons, class: 'lnr ', prefix: 'lnr-' },
      ],
    }
  },
  watch: {
    visible: {
      handler(value) {
        if (value != this.innerVisible)
          this.innerVisible = value;
        if (value) {
          this.activeTab = 0;
          this.bindItemData();
        }
      },
      immediate: true
    }
  },
  methods: {
    bindItemData() {
      this.selected = this.value;
      if (this.selected)
        this.activeTab = this.tabs.findIndex(x => this.selected.startsWith(x.class + x.prefix));
    },
    onSelect(selected) {
      this.selected = selected;
    },
    async onOk() {
      this.$emit("close");
      this.$emit("save", this.selected);
    },
    onCancel() {
      this.$emit("close");
    },
  },
}
</script>

<style scoped>
  .tab-content {
    max-height: 60vh; 
    overflow: auto;
  }
</style>